import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CompanyService } from 'src/lib/company.service';
import { CompanyAssetsLoaderService } from '../core/company-assets-loader.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyIdGuard implements CanActivate {
	// UUID/GUID regex pattern
	private readonly guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

	private readonly router = inject(Router);
	private readonly companyService = inject(CompanyService);
	private readonly companyAssetsLoaderService = inject(CompanyAssetsLoaderService);

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		const companyId = route.paramMap.get('companyId');

		if (!companyId || !this.guidPattern.test(companyId)) {
			// Invalid or missing company ID, redirect to error page
			this.router.navigate(['/errors/invalid-company-id'], {
				queryParams: { companyId },
			});
			return false;
		}

		await this.companyService.getCompanyAsync(companyId);
		await this.companyAssetsLoaderService.initAssetsAsync();

		return true;
	}
}
