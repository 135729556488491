import { AgdirApiEnvironment, AgdirGoogleEnvironment, AgdirOneSignalEnvironment, Environment } from '@agdir/environment/domain';
import { weHaveSharedMfas } from './we-have-shared-mfas';
import { weHaveSharedVendors } from './we-have-shared-vendors';

export const environment = new Environment({ env: 'dev', production: false });
environment
	.register(
		new AgdirApiEnvironment({
			webHost: 'https://agdir.dev',
			hostv3: 'https://v3.api.agdir.dev',
			host: 'https://api.agdir.dev',
		}),
	)
	.register(
		new AgdirGoogleEnvironment({
			apiKey: 'AIzaSyAd79l2jlY5ekNi-oX7lpbJbtNt6iP9_1c',
			reCaptchaV3SiteKey: '6LftOU0hAAAAAGvXGVjMOi-PUUxs6luj9GNiWp5G',
			googleAnalyticsTrackingCode: 'G-M3NJG4JP85',
		}),
	)
	.register(new AgdirOneSignalEnvironment({ appId: 'df1a4feb-ea03-4b82-9f82-1a312902d6e3' }));

weHaveSharedMfas(environment);
weHaveSharedVendors(environment);
