import { Platform } from '@angular/cdk/platform';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit, signal } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { ButtonComponent } from "@agdir/ui/button";

enum PLATFORMS {
	ANDROID = 'ANDROID',
	IOS = 'IOS',
}

interface BeforeInstallPromptEvent extends Event {
	prompt(): void;
}

@Component({
	selector: 'agdir-pwa-install-popup',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		@if (showPWA()) {
			<img alt="Agdir logo" width="90" height="90" ngSrc="/assets/logo/logo-color.svg" />
			<span class="text-xl text-gray-900 font-bold">{{ 'pwa.install.addAgdirPwa' | transloco }}</span>
			<span class="text-sm text-center text-gray-800">{{ 'pwa.install.subTitlePwa' | transloco }}</span>
			@if (showAndroidPWA()) {
				<agdir-button (click)="addToHomeScreen()" label="pwa.install.androidText" />
			}
			@if (showIOSPWA()) {
				<div class="text-center bg-gray-100 text-gray-700 p-3 ">
					{{ 'pwa.install.iosText1' | transloco }}
					<agdir-button icon="ios_share" style="vertical-align: middle" class="pb-1" />
					<br />
					{{ 'pwa.install.iosText2' | transloco }}
					<agdir-button icon="add_box" style="vertical-align: middle" class="pb-1" />
				</div>
			}
			<agdir-button (click)="closePwa()" icon="close" label="general.laterText" class="absolute top-3 right-3" />
		}
	`,
	styles: [
		`
			:host {
				@apply w-full flex flex-col fixed bottom-0 left-0 z-50 bg-blue-50 shadow-2xl p-5 gap-3 items-center justify-center text-center border pt-16;
			}
		`,
	],
	imports: [NgOptimizedImage, ButtonComponent, TranslocoPipe],
})
export class PwaInstallComponent implements OnInit {
	private platform = inject(Platform);

	modalPwaPlatform = signal<PLATFORMS | null>(null);
	showPWA = computed(() => this.modalPwaPlatform() !== null);
	showIOSPWA = computed(() => this.modalPwaPlatform() === PLATFORMS.IOS);
	showAndroidPWA = computed(() => this.modalPwaPlatform() === PLATFORMS.ANDROID);
	private modalPwaEvent = signal<BeforeInstallPromptEvent | null>(null);

	@HostBinding('class.hidden') get hidden() {
		return !this.modalPwaPlatform();
	}

	ngOnInit(): void {
		if (!localStorage.getItem('howToInstallPWA')) {
			this.loadModalPwa();
		}
	}

	public addToHomeScreen(): void {
		this.modalPwaEvent()?.prompt();
		this.modalPwaPlatform.set(null);
	}

	public closePwa(): void {
		localStorage.setItem('howToInstallPWA', 'done');
		this.modalPwaPlatform.set(null);
	}

	private loadModalPwa(): void {
		if (this.platform.ANDROID) {
			window.addEventListener('beforeinstallprompt', (event: any) => {
				event.preventDefault();
				this.modalPwaEvent.set(event);
				this.modalPwaPlatform.set(PLATFORMS.ANDROID);
			});
		}

		if (this.platform.IOS && this.platform.SAFARI) {
			const isInStandaloneMode = 'standalone' in window.navigator && (<any>window.navigator)['standalone'];
			if (!isInStandaloneMode) {
				this.modalPwaPlatform.set(PLATFORMS.IOS);
			}
		}
	}
}
