import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export function decirculize(obj: any): any {
	const cache: any[] = [];
	return JSON.parse(
		JSON.stringify(obj, (key, value) =>
			typeof value === 'object' && value !== null
				? cache.includes(value)
					? undefined // Duplicate reference found, discard key
					: cache.push(value) && value // Store value in our collection
				: value,
		),
	);
}

export function JsonCircularDepFixInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
	return next(
		req.clone({
			body: decirculize(req.body),
		}),
	);
}
