import { BaseComponent } from '@agdir/core';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject, OnInit, signal } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslocoPipe } from '@jsverse/transloco';
import { filter, takeUntil, tap } from 'rxjs';
import { ButtonComponent } from '../../../../../ui/button/button.component';

@Component({
	selector: 'agdir-pwa-update-popup',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		@if (modalVersion()) {
			<img alt="Agdir logo" width="90" height="90" ngSrc="/assets/logo/logo-color.svg" />
			<span class="text-xl text-gray-900 font-bold">{{ 'pwa.update.availableText' | transloco }}</span>
			<agdir-button (click)="updateVersion()" icon="system_update_alt" label="pwa.update.updateAction" />
		}
	`,
	styles: [
		`
			:host {
				z-index: 2147483647;
				@apply flex flex-col w-full fixed bottom-0 left-0 bg-blue-50 shadow-2xl p-5 gap-3 items-center justify-center text-center border;
			}
		`,
	],
	imports: [NgOptimizedImage, ButtonComponent, TranslocoPipe],
})
export class PwaUpdateComponent extends BaseComponent implements OnInit {
	modalVersion = signal<boolean>(false);
	chd = inject(ChangeDetectorRef);
	swUpdate = inject(SwUpdate);

	@HostBinding('class.hidden') get hidden() {
		return !this.modalVersion();
	}

	ngOnInit(): void {
		if (this.swUpdate.isEnabled) {
			this.swUpdate.versionUpdates
				.pipe(
					filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
					tap(() => {
						console.log('updated detected');
						this.modalVersion.set(true);
						this.chd.markForCheck();
						this.chd.detectChanges();
					}),
					takeUntil(this.destroyed$),
				)
				.subscribe();

			this.swUpdate.unrecoverable
				.pipe(
					tap(() => {
						window.location.reload();
					}),
					takeUntil(this.destroyed$),
				)
				.subscribe();
		}
	}

	public updateVersion(): void {
		this.modalVersion.set(false);
		window.location.reload();
	}
}
