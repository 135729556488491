import { HttpEvent, HttpHandlerFn, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError, from, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { AuthService, getToken } from '@agdir/domain';
import { IS_PUBLIC_API } from '@/http-service';
import { Router } from '@angular/router';
import { AgdirPopupService } from '@agdir/core';

const REFRESH_TOKEN_URL_PART = '/refresh-token';
let isRefreshing = false;
const refreshTokenSubject = new BehaviorSubject<string | null>(null);

export function AuthInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
	const authService = inject(AuthService);
	const router = inject(Router);
	const modal = inject(AgdirPopupService);

	if (isPublicApi(req)) {
		return next(req);
	}

	return from(authService.getCurrentCustomerSession()).pipe(
		switchMap(() => {
			const token = getToken();
			const newReq = hydrateWithToken(req, token || '');

			return next(newReq).pipe(
				catchError((error) => {
					if (error instanceof HttpErrorResponse) {
						if (error.status === 401 && !newReq.url.includes(REFRESH_TOKEN_URL_PART)) {
							return handleRefreshToken(req, next, authService, modal, router);
						}
						if (error.status === 403) {
							router.navigateByUrl('/forbidden');
							return EMPTY;
						}
					}
					return throwError(() => error);
				}),
			);
		}),
	);
}

function isPublicApi(req: HttpRequest<unknown>): boolean {
	return req.context.get(IS_PUBLIC_API) || req.method === 'JSONP' || req.url.startsWith('assets/') || req.url.startsWith('/assets/');
}

function hydrateWithToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
	return req.clone({
		headers: req.headers.set('Authorization', `Bearer ${token}`),
	});
}

function handleRefreshToken(
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
	authService: AuthService,
	modal: AgdirPopupService,
	router: Router,
): Observable<HttpEvent<unknown>> {
	if (!isRefreshing) {
		isRefreshing = true;
		refreshTokenSubject.next(null);

		return from(authService.refreshToken()).pipe(
			switchMap((token) => {
				refreshTokenSubject.next(token);
				return next(hydrateWithToken(request, token!));
			}),
			catchError((err) => {
				if (err instanceof HttpErrorResponse && [401, 403].includes(err.status)) {
					showUnauthorizedModal(modal, router, err, authService);
				}
				return EMPTY;
			}),
			finalize(() => {
				isRefreshing = false;
			}),
		);
	}

	return refreshTokenSubject.pipe(
		filter((token) => token !== null),
		take(1),
		switchMap((token) => next(hydrateWithToken(request, token!))),
	);
}

function showUnauthorizedModal(modal: AgdirPopupService, router: Router, error: any, authService: AuthService): void {
	modal.open({
		title: 'Unauthorized',
		message: `Looks like you've been logged out by following: ${error.message}`,
		buttons: [
			{
				label: 'Reload Page',
				color: 'outline',
				onClick: () => {
					window.location.reload();
				},
			},
			{
				label: 'Login Again',
				color: 'primary',
				onClick: async (ref) => {
					authService.signOut();
					ref.close();
					router.navigate(['/']);
				},
			},
		],
	});
}
