import { IsAuthenticated, SplashScreenGuard, themeActivatorsGuard } from '@agdir/services';
import { Route } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';
import { CustomerAssetsResolver } from './core/customer-assets.resolver';
import { Error404Component } from './pages/error-page/error-404/error-404.component';

export const appRoutes: Route[] = [
	{ path: 'j/:code', redirectTo: '/auth/join/:code' },
	{
		path: '',
		pathMatch: 'full',
		resolve: { assets: CustomerAssetsResolver },
		canActivate: [IsAuthenticated, SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/authenticated-page/authenticated-page-routing.module').then((m) => m.AUTHENTICATED_PAGE_ROUTES),
	},
	{
		path: 'tripletex',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/tripletex-page/tripletex-page.routes').then((m) => m.ROUTES),
		providers: [provideTranslocoScope({ scope: 'tripletex', alias: 'tripletex' })],
	},
	{
		path: 'components',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/components-page/components-page.routes').then((m) => m.ROUTES),
	},
	{
		path: 'new',
		canActivate: [IsAuthenticated, SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('@agdir/heimdall').then((m) => m.NEW_COMPANY_ROUTES),
	},
	{
		path: 'auth',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		data: { title: 'Auth', theme: 'green' },
		loadChildren: () => import('@agdir/heimdall').then((m) => m.AUTH_ROUTES),
	},
	{
		path: 'forbidden',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadComponent: () => import('./pages/error-page/error-403/error-403.component').then((m) => m.Error403Component),
	},
	{
		path: ':companyId',
		canActivate: [IsAuthenticated, SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/authenticated-page/authenticated-page-routing.module').then((m) => m.AUTHENTICATED_PAGE_ROUTES),
	},
	{ path: '**', canActivate: [SplashScreenGuard, themeActivatorsGuard], component: Error404Component },
];
