import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class CompanyIdGuard implements CanActivate {
	// UUID/GUID regex pattern
	private readonly guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const companyId = route.paramMap.get('companyId');

		if (!companyId || !this.guidPattern.test(companyId)) {
			// Invalid or missing company ID, redirect to error page
			this.router.navigate(['/errors/invalid-company-id'], {
				queryParams: { companyId },
			});
			return false;
		}

		return true;
	}
}
