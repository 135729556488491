import { OfflineDetectComponent, PwaModule } from '@agdir/layout';
import { SentryWrapperService } from '@agdir/sentry';
import { AnalyticsService, AuthService, GtagSetProps } from '@agdir/services';
import { SkeletonBarComponent } from '@agdir/ui/loaders';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
	standalone: true,
	selector: 'agdir-root',
	template: `
		<ngx-loading-bar [fixed]="true" [includeSpinner]="false" color="#405c70" height="10px" />
		<router-outlet />
		<agdir-pwa-update-popup />
		<agdir-pwa-install-popup />
		<agdir-offline-detect class="bg-warn bottom-0 fixed px-10 py-2 text-center text-blue-50 w-full z-10" />
		@if (isLoading | async) {
			<agdir-skeleton-bar class=" h-0.5 fixed top-0 left-0 w-full bg-gray-600" />
		}
	`,
	host: { class: 'block' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LoadingBarModule, RouterOutlet, PwaModule, OfflineDetectComponent, SkeletonBarComponent, AsyncPipe],
})
export class AppComponent {
	protected isLoading = inject(Router).events.pipe(
		filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationCancel),
		map((event) => event instanceof NavigationStart),
		startWith(false),
	);
	private readonly translate = inject(TranslocoService);
	private readonly document = inject(DOCUMENT);
	private readonly router = inject(Router);
	private readonly analyticsService = inject(AnalyticsService);
	private readonly authService = inject(AuthService);
	private readonly sentry = inject(SentryWrapperService, { optional: true });
	private readonly user = toSignal(this.authService.getCurrentCustomerSession());
	private readonly langChanges = toSignal(this.translate.langChanges$);

	constructor() {
		effect(() => {
			const u = this.user();
			if (u?.id) {
				this.sentry?.setUserContext(u);
				this.analyticsService.gtagSet(GtagSetProps.USER_ID, u.id);
			}
		});
		effect(() => {
			const language = this.langChanges();
			if (language) {
				this.document.documentElement.lang = language;
			}
		});
		effect(() => {
			this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
				this.analyticsService.logPageView((event as unknown as NavigationEnd).urlAfterRedirects);
			});
		});
	}
}
