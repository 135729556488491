import { Route } from '@angular/router';
import { provideTranslocoScope } from '@jsverse/transloco';
import { CompanyIdGuard } from './guards/company-id-guard';
import { IsAuthenticated } from './guards/is-authenticated.guard';
import { SplashScreenGuard } from './guards/splash-screen-guard.service';
import { themeActivatorsGuard } from './guards/theme-activators.guard';
import { RootPageComponent } from './pages/root-page.component';
import { canActivatePowerUser } from './power/guards/is-power-user.guard';

export const appRoutes: Route[] = [
	{ path: 'j/:code', redirectTo: '/auth/join/:code' },
	{
		path: '',
		pathMatch: 'full',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		component: RootPageComponent,
		providers: [provideTranslocoScope({ scope: 'invitations', alias: 'invitations' })],
		// loadComponent: () => import('./pages/root-page.component').then((m) => m.RootPageComponent),
	},
	{
		path: 'new-stripe-subscription/:stripeSessionId',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadComponent: () =>
			import('./pages/new-stripe-subscription-page/new-stripe-subscription-page.component').then((m) => m.NewStripeSubscriptionPageComponent),
	},
	{
		path: 'errors/invalid-company-id',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadComponent: () => import('./pages/error-page/invalid-company-id-error.component').then((m) => m.InvalidCompanyIdErrorComponent),
	},
	{
		path: 'tripletex',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/tripletex-page/tripletex-page.routes').then((m) => m.ROUTES),
		providers: [provideTranslocoScope({ scope: 'tripletex', alias: 'tripletex' })],
	},
	{
		path: 'components',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/components-page/components-page.routes').then((m) => m.ROUTES),
	},
	{
		path: 'new',
		canActivate: [IsAuthenticated, SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/new-company-page/new-company-page.routing').then((m) => m.NEW_COMPANY_ROUTES),
	},
	{
		path: 'auth',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		data: { title: 'Auth', theme: 'green' },
		loadChildren: () => import('./pages/auth-page/auth.routes').then((m) => m.AUTH_ROUTES),
	},
	{
		path: 'forbidden',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadComponent: () => import('./pages/error-page/error-403/error-403.component').then((m) => m.Error403Component),
	},
	{
		path: 'power',
		canActivate: [IsAuthenticated, canActivatePowerUser, SplashScreenGuard, themeActivatorsGuard],
		canActivateChild: [canActivatePowerUser],
		loadChildren: () => import('./power/app.routes').then((m) => m.appRoutes),
	},
	{
		path: ':companyId',
		canActivate: [IsAuthenticated, CompanyIdGuard, SplashScreenGuard, themeActivatorsGuard],
		loadChildren: () => import('./pages/authenticated-page/authenticated-page-routing.module').then((m) => m.AUTHENTICATED_PAGE_ROUTES),
	},
	{
		path: '**',
		canActivate: [SplashScreenGuard, themeActivatorsGuard],
		loadComponent: () => import('./pages/error-page/error-404/error-404.component').then((m) => m.Error404Component),
	},
];
