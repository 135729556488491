import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '@agdir/environment/angular';
import { AgdirApiEnvironment } from '@agdir/environment/domain';
import { inject } from '@angular/core';

const LOCAL_PROXY_REWRITES = ['http://localhost:3000', 'http://localhost:3001'];
const EXTERNAL_SECURE_SITE = 'https://';
const EXTERNAL_SAME_PROTOCOL_SITE = '//';
const ASSETS = 'assets/';

export function HostPrefixInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
	const environment = inject(ENVIRONMENT);
	const { host, urdrHost, verdandiHost } = environment.get(AgdirApiEnvironment);

	if (shouldSkipUrlTransform(req.url)) {
		return next(req);
	}

	const url = makeRoute(req.url, { host, urdrHost, verdandiHost });
	return next(req.clone({ url: cleanUrl(url) }));
}

function shouldSkipUrlTransform(url: string): boolean {
	return (
		url.startsWith(EXTERNAL_SECURE_SITE) ||
		url.startsWith(EXTERNAL_SAME_PROTOCOL_SITE) ||
		url.startsWith(ASSETS) ||
		url.startsWith(ASSETS, 1) ||
		LOCAL_PROXY_REWRITES.some((prefix) => url.startsWith(prefix))
	);
}

function makeRoute(url: string, hosts: { host: string; urdrHost?: string; verdandiHost?: string }): string {
	if (url.includes('urdr/')) {
		return `${hosts.urdrHost ?? hosts.host}/${url}`;
	}
	if (url.includes('verdandi/')) {
		return `${hosts.verdandiHost ?? hosts.host}/${url}`;
	}
	return `${hosts.host}/${url}`;
}

function cleanUrl(url: string): string {
	return url.replace(/([^:]\/)\/+/g, '$1');
}
