import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

export const themeActivatorsGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	// check if route contains data object {theme} and if yes, set document body attribute to theme
	// if not, set document body attribute to default theme
	// return true;
	const theme = route.data?.['theme'];
	const themeName = theme ? theme : 'default';
	document.body.setAttribute('data-theme', themeName);
	return true;
};
