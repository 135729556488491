import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from '@agdir/environment/domain';
import { Integration } from '@sentry/types';
import * as version from '../../../../version.json';

export class AgdirSentryEnvironment extends AgdirFeatureEnvironment {
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirSentryEnvironment';
	readonly dsn: string;
	readonly integrations: Integration[];
	// Performance Monitoring
	readonly tracesSampleRate: number;
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	readonly tracePropagationTargets: Array<string | RegExp>;
	// This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	readonly replaysSessionSampleRate: number;
	// If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	readonly replaysOnErrorSampleRate: number;
	readonly release? = version.BUILD;

	constructor({
		dsn,
		integrations,
		tracesSampleRate,
		tracePropagationTargets,
		replaysSessionSampleRate,
		replaysOnErrorSampleRate,
		release,
	}: AgdirSentryEnvironment) {
		super(AgdirSentryEnvironment.NAME);
		this.dsn = dsn;
		this.integrations = integrations;
		this.tracesSampleRate = tracesSampleRate;
		this.tracePropagationTargets = tracePropagationTargets;
		this.replaysSessionSampleRate = replaysSessionSampleRate;
		this.replaysOnErrorSampleRate = replaysOnErrorSampleRate;
		if (release) {
			this.release = release;
		}
	}
}
