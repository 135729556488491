import { CUSTOMER_PATHS } from '@/constants';
import { AgdirCardComponent, LocalStore } from '@agdir/core';
import { AuthService, Company, SharesService } from '@agdir/domain';
import { PageComponent, PageContentComponent, PageHeaderComponent } from '@agdir/layout';
import { ButtonComponent } from '@agdir/ui/button';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LottieComponent } from 'ngx-lottie';
import { CustomerInvitationsListComponent } from '../components/customer-invitations-list.component';

@Component({
	selector: 'agdir-root-page',
	template: `
		<agdir-page>
			<ng-lottie [options]="{ path: '/assets/agdir_logo_lottie.json' }" class="w-10 pb-2" />

			<agdir-page-header title="Welcome to Agdir">
				<div menu class="flex flex-row justify-end items-center gap-2">
					@if (isRedirecting()) {
						<agdir-spinner />
					} @else {
						<agdir-button size="small" (click)="tryReload()">Try reload</agdir-button>
						<agdir-button size="small" (click)="goToLogin()">Go to login</agdir-button>
					}
				</div>
			</agdir-page-header>
			<agdir-page-content>
				<agdir-card class="ag-grid-full">
					@if (isAuthenticated$ | async; as profile) {
						<section>
							<span class="text-green-900">✓</span>
							<span>{{ profile.name }} {{ profile.surname }}</span>
						</section>
						@if (lastCompany) {
							<section>
								<span class="text-green-800">✓</span>
								<span>Last time seen on {{ lastCompany.organizationName }}. redirecting....</span>
							</section>
						} @else if (redirectingToFirstCompany()) {
							<section>
								<span class="text-green-700">×✓</span>
								<span>Redirecting to {{ redirectingToFirstCompany()?.organizationName }}</span>
							</section>
						} @else {
							<section>
								<span class="text-red-600">×</span>
								<span>No companies found. Try reload</span>
							</section>
							<agdir-customer-invitations-list class="w-full" (accepted)="tryReload()" />
						}
					} @else {
						<section>
							<span class="text-red-600">×</span>
							<span>Anonymous. Redirecting to login</span>
						</section>
					}
				</agdir-card>
			</agdir-page-content>
		</agdir-page>
	`,
	styles: [' section{@apply flex flex-row items-center gap-2 justify-start}'],
	imports: [
		LottieComponent,
		AsyncPipe,
		ButtonComponent,
		AgdirCardComponent,
		PageComponent,
		PageContentComponent,
		PageHeaderComponent,
		SpinnerComponent,
		CustomerInvitationsListComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class RootPageComponent implements OnInit {
	isAuthenticated$ = inject(AuthService).getCurrentCustomer();
	lastCompany = LocalStore.get<Company>('lastCompany');
	sharesService = inject(SharesService);
	redirectingToFirstCompany = signal<Company | null>(null);
	noCompaniesFound = signal(false);
	isRedirecting = signal<boolean>(false);
	authService = inject(AuthService);
	route = inject(ActivatedRoute);
	autoRedirectingEnabled = signal<boolean>(!this.route.snapshot?.queryParamMap?.has('debug'));

	ngOnInit(): void {
		setTimeout(async () => {
			const user = await this.isAuthenticated$;
			if (!user && this.autoRedirectingEnabled()) {
				this.goToLogin();
			} else {
				const allCompanies = await this.sharesService.getMyCompaniesAsync();
				if (this.lastCompany?.id && this.autoRedirectingEnabled() && allCompanies.some((c) => c.id === this.lastCompany?.id)) {
					window.location.href = this.lastCompany.id + '/dashboard';
				}
				const [firstCompanyInList] = allCompanies;
				if (firstCompanyInList && this.autoRedirectingEnabled()) {
					this.redirectingToFirstCompany.set(firstCompanyInList);
					window.location.href = firstCompanyInList.companyId + '/dashboard';
				} else if (this.autoRedirectingEnabled()) {
					this.noCompaniesFound.set(true);
				}
			}
		}, 1000);
	}

	async tryReload() {
		this.isRedirecting.set(true);
		await this.authService.refreshToken();
		window.location.reload();
	}

	async goToLogin() {
		this.isRedirecting.set(true);
		await this.authService.signOut();
		window.location.href = CUSTOMER_PATHS.AUTH_SIGNIN;
	}
}
